
export default {
  data () {
    return {
      isMounted: false,
      isMenuOpen: false,
      headerActiveTab: null,
      keyword: null,
      menuPrimaryItems: {
        Home: '/',
        Royaltynieuws: '/royaltynieuws',
        'Activiteiten': '/activiteiten',
        'Programma\'s': {
          expand: true,
          children: {
            'TV uitzendingen': '/programmas/blauw-bloed-tv',
            'Blauw Bloed podcast': '/programmas/blauw-bloed-podcast',
            'De onbekende titels van de koning': '/programmas/de-onbekende-titels-van-de-koning',
            'De juwelen van de royals': '/programmas/de-juwelen-van-de-royals',
            'Koninklijke mode': '/programmas/koninklijke-mode',
          },
        },
        'Fotoboeken': '/rubriek/fotoboeken',
        'Royals': '/royals',
      },
      menuSecondaryItems: {
        'Over Blauw Bloed': '/over-blauw-bloed',
        Contact: '/contact',
        'Word vriend': 'https://www.eo-acties.nl/blauwbloed?utm_source=homepage%20blauw%20bloed&utm_medium=menu&utm_campaign=word%20vriend',
      },
      menuSocialItems: {
        facebook: 'https://www.facebook.com/BlauwBloedtv',
        instagram: 'https://www.instagram.com/eo_blauwbloed/',
        youtube: 'https://www.youtube.com/channel/UCyd79AfHQtLR-wLxjyzLIxw',
        spotify: 'https://open.spotify.com/show/0MafBHgc9RaHfmaORXvtkM?si=a7c7d201490d4f47',
        twitter: 'https://twitter.com/eo_blauwbloed',
      },
      footerShortcuts: {
        'Ga naar...': {
          Royaltynieuws: '/royaltynieuws',
          'Royals': '/royals',
          'Activiteiten': '/activiteiten',
          'TV uitzendingen': '/programmas/blauw-bloed-tv',
          'Over Blauw Bloed': '/over-blauw-bloed',
          'Blauw Bloed Podcast': '/programmas/blauw-bloed-podcast',
          Contact: '/contact',
          'Fotoboeken': '/rubriek/fotoboeken',
        },
      },
      footerSocials: {
        'Volg ons op...': {
          instagram: {
            name: 'Instagram',
            path: 'https://www.instagram.com/eo_blauwbloed/',
          },
          facebook: {
            name: 'Facebook',
            path: 'https://www.facebook.com/BlauwBloedtv',
          },
          youtube: {
            name: 'Youtube',
            path: 'https://www.youtube.com/channel/UCyd79AfHQtLR-wLxjyzLIxw',
          },
          twitter: {
            name: 'Twitter',
            path: 'https://twitter.com/eo_blauwbloed',
          },
          spotify: {
            name: 'Spotify',
            path: 'https://open.spotify.com/show/0MafBHgc9RaHfmaORXvtkM?si=a7c7d201490d4f47',
          },
        },
      },
    }
  },
  head: {
    link: [
      {
        rel: 'preload',
        as: 'style',
        href: 'https://cookies.eo.nl/sites/EO/eo.nl/npo-lowerbar_v3.1.14.css',
        crossorigin: '',
      },
    ],
  },
  computed: {
    breadcrumbs () {
      return this.$store.getters['breadcrumbs/items'](this.pathWithoutTrailingSlash)
    },
    breadcrumbsWidth () {
      return this.$store.getters['breadcrumbs/width'](this.pathWithoutTrailingSlash)
    },
    isBreadcrumbsFloating () {
      return this.$store.getters['breadcrumbs/floating'](this.pathWithoutTrailingSlash)
    },
    hasBreadcrumbsEoRoot () {
      return this.$store.getters['breadcrumbs/eoRoot'](this.pathWithoutTrailingSlash)
    },
    pathWithoutTrailingSlash () {
      return this.$route.path.replace(/\/$/, '')
    },
  },
  mounted () {
    this.isMounted = true
  },
  methods: {
    onNavigate () {
      this.isMenuOpen = false
    },
    onSearch (keyword) {
      this.$router.push({
        path: '/zoeken',
        query: {
          invoer: keyword,
        },
      })
      this.isMenuOpen = false
      this.keyword = null
    },
  },
}
