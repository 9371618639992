export default {
  APP_ENV: process.env.APP_ENV || 'production',
  APP_VERSION: process.env.BITBUCKET_TAG || `v0.0.0-rc.${process.env.BITBUCKET_BUILD_NUMBER}`,
  BASE_URL: (process.env.BITBUCKET_PR_ID && `https://pr-${process.env.BITBUCKET_PR_ID}-blauw-bloed.feat.eo.nl`) || process.env.BASE_URL || 'https://blauwbloed.eo.nl',
  BASE_URL_CANONICAL: process.env.BASE_URL_CANONICAL || 'https://blauwbloed.eo.nl',
  COMMUNITY_NAME: "Blauw Bloed",
  EO_API_HOST: process.env.EO_API_HOST || 'https://prod-api.eo.nl',
  NEW_EO_API_HOST: process.env.NEW_EO_API_HOST || 'https://api.eo.nl',
  EO_API_VERSION: 'v1',
  PREPR_ENVIRONMENT_DOMAIN: process.env.PREPR_ENVIRONMENT_DOMAIN || 'blauwbloed-eo',
  PREPR_COMMUNITY_ID: process.env.PREPR_COMMUNITY_ID || '4afdeff2-2983-4cf7-afe2-a2d5ceabce22',
  PLAYER_KEY: process.env.PLAYER_KEY,
  PLAYER_ANALYTICS_KEY: process.env.PLAYER_ANALYTICS_KEY,
}