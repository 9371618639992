export const EoLikeButton = () => import('../../node_modules/@eo/nuxt-like/lib/components/EoLikeButton.vue' /* webpackChunkName: "components/eo-like-button" */).then(c => wrapFunctional(c.default || c))
export const PreprPage = () => import('../../components/PreprPage.vue' /* webpackChunkName: "components/prepr-page" */).then(c => wrapFunctional(c.default || c))
export const SterBanner = () => import('../../components/SterBanner.vue' /* webpackChunkName: "components/ster-banner" */).then(c => wrapFunctional(c.default || c))
export const BlauwBloedCardArticle = () => import('../../components/BlauwBloedCard/Article.vue' /* webpackChunkName: "components/blauw-bloed-card-article" */).then(c => wrapFunctional(c.default || c))
export const BlauwBloedCardDefault = () => import('../../components/BlauwBloedCard/Default.vue' /* webpackChunkName: "components/blauw-bloed-card-default" */).then(c => wrapFunctional(c.default || c))
export const BlauwBloedCardEpisode = () => import('../../components/BlauwBloedCard/Episode.vue' /* webpackChunkName: "components/blauw-bloed-card-episode" */).then(c => wrapFunctional(c.default || c))
export const BlauwBloedCardProgram = () => import('../../components/BlauwBloedCard/Program.vue' /* webpackChunkName: "components/blauw-bloed-card-program" */).then(c => wrapFunctional(c.default || c))
export const BlauwBloedCard = () => import('../../components/BlauwBloedCard/index.vue' /* webpackChunkName: "components/blauw-bloed-card" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
