// Vue plugins for both server and client side
import Vue from 'vue'
import componentLibrary from '@eo/component-library'
import plugins from "@eo/component-library/src/plugins"

const options = JSON.parse(`{"isNuxt":true,"debug":false,"namespace":"component-library","baseUrl":"https://blauwbloed.eo.nl","cloudflareCdn":"https://blauwbloed.eo.nl/cdn-image","preprEnvironment":"blauwbloed-eo","themeFile":"~/assets/css/eo.variables.scss","npoTag":{"brand":"Blauwbloed","brandId":630011},"EoRenderer":{"Asset":{"class":"my-2","Document":{"target":"_blank"}},"NewsletterSubscribe":{"accentColor":"secondary","color":"white","textColor":"black"},"Quote":{"context":"blauw-bloed"}}}`)
Vue.use(componentLibrary, options)

export default async (context, inject) => {
  // Register all plugins from library also in Nuxt global context
  Object.entries(plugins).forEach(([name]) => {
    inject(name, Vue.prototype['$' + name])
  })
}